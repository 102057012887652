import { ThemeConfig } from 'tailwindcss/types/config'

const screens = {
  ['xxs']: '320px',
  ['xs']: '480px',
  ['sm']: '640px',
  ['md']: '768px',
  ['lg']: '1024px',
  ['xl']: '1280px',
  ['1xl']: '1440px',
  ['2xl']: '1680px',
  ['3xl']: '1920px',
  ['4xl']: '2200px',
  ['5xl']: '2560px',
  ['6xl']: '3840px',
  ['desktop']: { min: '1125px' },
  ['mobile']: { max: '1124px' }
} satisfies ThemeConfig['screens']

export { screens }
